<template lang="pug">
FCard.FcwBlogPostCard(
  :style="style"
  width="100%"
)
  FImage.FcwBlogPostCard__image(
    v-if="image.src"
    v-bind="image"
    fit="cover"
    height="100%"
    width="100%"
    loading="eager"
  )
  .FcwBlogPostCard__item(:class="itemClasses")
    FcwChip.FcwBlogPostCard__chip(
      v-if="tag"
      size="small"
      round
      :label="tag"
    )
    h4 {{ title }}
    p.FcwBlogPostCard__date {{ formatDate(date, { format: 'd MMMM' }) }}
</template>

<style lang="stylus">
.FcwBlogPostCard
  overflow hidden

  &:hover
    .FcwBlogPostCard__image
      transform scale(1.1)

.FcwBlogPostCard__item
  display flex
  flex-direction column
  align-items flex-start
  justify-content flex-start
  min-height rem(280)
  padding rem(24)
  position relative
  box-sizing border-box
  border-radius rem(16)
  background-image var(--FcwBlogPostCard--gradient)

  h4
    color var(--color--neutral--light-5)
    margin-top rem(8)

  &--overlay
    gradient-overlay()

.FcwBlogPostCard__image
  &.FImage
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    transition transform 400ms ease

.FcwBlogPostCard__chip
  text-transform uppercase

.FcwBlogPostCard__date
  color var(--color--neutral--light-5)
</style>

<script setup lang="ts">
import type { FImageProps } from '@fifteen/design-system-vue';

export interface FcwBlogPostCardProps {
  /**
   * Slug of the blog post
   */
  slug: string;
  /**
   * Title of the blog post
   */
  title: string;
  /**
   * Category of the blog post
   */
  tag?: string;
  /**
   * Publication date of the blog post
   */
  date: string;
  /**
   * Image of the card
   */
  image?: Pick<FImageProps, 'src' | 'alt'>;
  /**
   * Background of the card: it can be a linear gradient if `background` is an array of 2 colors
   */
  background?: Color | [Color, Color];
}

const props = withDefaults(defineProps<FcwBlogPostCardProps>(), {
  background: () => ['primary--light-1', 'primary--dark-1'],
  image: () => ({ src: '', alt: '' }),
  tag: '',
});

const { formatDate } = useLocaleFormat();

const itemClasses = computed<VueClasses>(() => ({
  'FcwBlogPostCard__item--overlay': !!props.image?.src,
}));

const gradientFrom = computed(() =>
  Array.isArray(props.background) ? props.background[0] : props.background
);
const gradientTo = computed(() =>
  Array.isArray(props.background) ? props.background[1] : props.background
);

const style = computed<Style>(() => ({
  '--FcwBlogPostCard--gradient': `linear-gradient(90deg, ${getCssColor(
    gradientFrom.value
  )} -20%, ${getCssColor(gradientTo.value)} 120%)`,
}));
</script>
